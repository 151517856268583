export const csvFileColumns:any = {
    uniqueAccountID: "UNIQUE_ACCOUNT_IDENTIFIER",
    sellerDepartVATNumberCountry: "SELLER_DEPART_VAT_NUMBER_COUNTRY",
    sellerDepartVATNumber: "SELLER_DEPART_COUNTRY_VAT_NUMBER",
    activityPeriod: "ACTIVITY_PERIOD",
    transactionCompleteDate: "TRANSACTION_COMPLETE_DATE",
    transactionCurrencyCode: "TRANSACTION_CURRENCY_CODE",
    priceOfItemsVatRatePercent: "PRICE_OF_ITEMS_VAT_RATE_PERCENT",
    shipChargeVatRatePercent: "SHIP_CHARGE_VAT_RATE_PERCENT",
    totalActivityValueAmountVATIncluded: "TOTAL_ACTIVITY_VALUE_AMT_VAT_INCL",
    totalActivityValueAmountVATExcluded: "TOTAL_ACTIVITY_VALUE_AMT_VAT_EXCL",
    totalActivityValueVATAmount: "TOTAL_ACTIVITY_VALUE_VAT_AMT",
    vatCalculationImputationCountry: "VAT_CALCULATION_IMPUTATION_COUNTRY",
    exportOutsideEU: "EXPORT_OUTSIDE_EU",
    taxableJurisdiction: "TAXABLE_JURISDICTION",
    buyerVATNumber: "BUYER_VAT_NUMBER",
    taxReportingScheme: "TAX_REPORTING_SCHEME",
    transactionType: "TRANSACTION_TYPE",
    saleDepartCountry: "SALE_DEPART_COUNTRY",
    saleArrivalCountry: "SALE_ARRIVAL_COUNTRY",
    arrivalPostCode: "ARRIVAL_POST_CODE",
    marketplace: "MARKETPLACE"
  }