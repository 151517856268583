<template>
  <div v-if="missingVATNumbers && missingVATNumbers.length > 0">
    {{ getLocalizedText(labels.title) }}
    <b-table 
      class="missingVATNumbers"
      outlined striped
      :items="missingVATNumbers"
      :fields="missingVATNumbersFields"
      ref="missingVATNumbers">
      <template v-slot:cell(arrivalCountries)="row">
        <b-table 
          outlined striped
          :items="row.item.arrivalCountries"
          :fields="arrivalCountriesFields"
          ref="missingVATNumbers">
        </b-table>
      </template>    
    </b-table>
  </div>
</template>

<style>
table.missingVATNumbers table {
  margin: 0;
  padding: 0;
  width: 100%;
}

table.missingVATNumbers td:has(> table) {
  padding:0;
}

table.missingVATNumbers table th {
  display:none;
}
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';

export default defineComponent({
    props: {
        missingVATNumbers: {
          type: Array,
          required: false
        }
    },
    components: {
      
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

      const labels = {
        "title" : {
          "fr" : "Lignes avec numéros de TVA manquants",
          "en" : "Rows with missing VAT numbers"
        },
        "headerDepartureCountryHeader" : {
          "fr" : "Pays de départ",
          "en" : "Departure country"
        },
        "headerArrivalCountriesHeader" : {
          "fr" : "Pays d'arrivée / VAT % / Lignes",
          "en" : "Arrival countries / VAT % / Rows"
        }
      }

      const missingVATNumbersFields = ref([
        {
          key: "departureCountry",
          label: ""
        },
        {
          key: "arrivalCountries",
          label: ""
        }
      ])

      const arrivalCountriesFields = [
        {
          key: "arrivalCountry"
        },
        {
          key: "vatPercentKey"
        },
        {
          key: "rows",
          formatter: (value:string[], key:any, item:any) => {
            return value.join(", ")
          }
        },
      ]

      onMounted(() => {
        updateTableLabels();
      })

      const updateTableLabels = () => {
        missingVATNumbersFields.value[0].label = app.getLocalizedText(labels.headerDepartureCountryHeader) as string;
        missingVATNumbersFields.value[1].label = app.getLocalizedText(labels.headerArrivalCountriesHeader) as string;
      }

      watch(
        currentLanguageCode,
        (val:any, oldVal:any) => {
          updateTableLabels();
        },
        { deep: false }
      )

      return { 
        missingVATNumbers:props.missingVATNumbers,
        missingVATNumbersFields,
        arrivalCountriesFields,
        labels
      }
    }
})
</script>