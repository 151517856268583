<template>
    <b-table 
      v-if="ecritures"
      outlined striped
      :items="ecritures"
      :fields="reportEcrituresFields"
      ref="ecrituresTable">
    </b-table>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, formatDate, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';

export default defineComponent({
    props: {
        ecritures: {
          type: Array,
          required: true
        }
    },
    components: {
      
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { ecritures } = toRefs(props);

      const reportEcrituresFields = [
        {
          key: "codeJournal",
          label: "Code Journal"
        },
        {
          key: "dateEcriture",
          label: "Date"
        },
        {
          key: "compteNumber",
          label: "Compte"
        },
        {
          key: "compteLabel",
          label: "Libellé"
        },
        {
          key: "debit",
          label: "Débit",
          formatter: (value:number, key:any, item:any) => {
            return app.formatPriceAmount({
              amount: value
            });
          }
        },
        {
          key: "credit",
          label: "Crédit",
          formatter: (value:number, key:any, item:any) => {
            return app.formatPriceAmount({
              amount: value
            });
          }
        },
        {
          key: "countryCode",
          label: "Pays"
        }
      ];

      
      onMounted(() => {

      })


      return { 
        ecritures,
        reportEcrituresFields,
      }
    }
})
</script>