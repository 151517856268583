<template>
  <div class="report">
    <div v-if="report.differenceEcritures != undefined">
      {{ getLocalizedText(labels.differenceEcritures) }}: <b>{{ formatPriceAmount({ amount: report.differenceEcritures }) }}</b>
    </div>
    <div v-if="report.differenceComminglings != undefined">
      {{ getLocalizedText(labels.differenceComminglings) }}: <b>{{ formatPriceAmount({ amount: report.differenceComminglings }) }}</b>
    </div>
    <br/>
    <RowUsed
      :type="'rowsUsedSeveralTimes'"
      :rowUsed="report.rowsUsedSeveralTimes"
    />
    <div v-if="report.rowsUsedSeveralTimesTotal && report.rowsUsedSeveralTimesTotal != 0">
      {{ getLocalizedText(labels.rowsUsedSeveralTimesTotal) }}: {{ formatPriceAmount({ amount: report.rowsUsedSeveralTimesTotal }) }}
    </div>
    <RowUsed
      :type="'rowsUsedInEcrituresButNotMarketplace'"
      :rowUsed="report.rowsUsedInEcrituresButNotMarketplace"
    />
    <RowUsed
      :type="'rowsUsedInMarketplaceButNotEcritures'"
      :rowUsed="report.rowsUsedInMarketplaceButNotEcritures"
    />
    <div v-if="report.differenceRowsEcrituresMarketplace != undefined">
      {{ getLocalizedText(labels.differenceRowsEcrituresMarketplace) }}: {{ formatPriceAmount({ amount: report.differenceRowsEcrituresMarketplace }) }}  
    </div>
    <div v-if="report.rowsWithoutAmount && report.rowsWithoutAmount.length > 0">
      {{ getLocalizedText(labels.rowsWithoutAmount) }}: {{ report.rowsWithoutAmount.join(', ')}}  
    </div>
    <div v-if="report.totalEcritures != undefined">
      {{ getLocalizedText(labels.totalEcritures) }}: {{ formatPriceAmount({ amount: report.totalEcritures }) }}
    </div>
    <div v-if="report.totalComminglings">
      {{ getLocalizedText(labels.totalComminglings) }}: {{ formatPriceAmount({ amount: report.totalComminglings.total }) }}<br/>
      {{ getLocalizedText(labels.totalComminglingsBUY) }}: {{ formatPriceAmount({ amount: report.totalComminglings.buy }) }}<br/>
      {{ getLocalizedText(labels.totalComminglingsSELL) }}: {{ formatPriceAmount({ amount: report.totalComminglings.sell }) }}
    </div>
    <br/>
    <div>
      <ConversionRates :conversionRates="report.conversionRates" />
    </div>
    <div>
      <VATPercentsUsed :vatPercentsUsed="report.vatPercentsUsed" />
    </div>
    <div>
      <MissingVATNumbers :missingVATNumbers="report.missingVATNumbers" />
    </div>
    <!--
    <Ecritures :ecritures="report.ecritures" />
    -->
  </div>
</template>

<style>
  .report table {
    width: auto;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, formatDate, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';

import RowUsed from './RowUsage.vue';
import ConversionRates from './ConversionRates.vue';
import VATPercentsUsed from './VATPercentsUsed.vue';
import MissingVATNumbers from './MissingVATNumbers.vue';
import Ecritures from './Ecritures.vue';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';

export default defineComponent({
    props: {
        report: {
          type: Object,
          required: true
        }
    },
    components: {
      RowUsed,
      ConversionRates,
      VATPercentsUsed,
      MissingVATNumbers,
      Ecritures
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

      const { report } = toRefs(props);


      const labels = {
        "differenceEcritures" : {
          "fr": "Différence ecritures (credit - debit)",
          "en": "Difference ecritures (credit - debit)"
        },
        "differenceComminglings" : {
          "fr": "Différence comminglings (credit - debit)",
          "en": "Difference comminglings (credit - debit)"
        },
        "differenceRowsEcrituresMarketplace": {
          "fr": "Différence liée aux lignes non utilisées dans ecritures ou marketplace",
          "en": "Difference related to rows not used in ecritures or marketplace"
        },
        "rowsUsedSeveralTimesTotal": {
          "fr": "Impact total sur les montants (lignes utilisées plusieurs fois)",
          "en": "Total impact on amounts (rows used several times)"
        },
        "rowsWithoutAmount" : {
          "fr": "Lignes sans montant",
          "en": "Rows without amount"
        },
        "totalEcritures" : {
          "fr": "Montant total ecritures",
          "en": "Total ecritures amount"
        },
        "totalComminglings" : {
          "fr": "Montant total comminglings",
          "en": "Total comminglings amount"
        },
        "totalComminglingsBUY" : {
          "fr": "Montant total comminglings BUY",
          "en": "Total comminglings BUY amount"
        },
        "totalComminglingsSELL" : {
          "fr": "Montant total comminglings SELL",
          "en": "Total comminglings SELL amount"
        }

      }

      const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

      return { 
        report,
        labels,
        hasUserRole,
        apiRoles
      }
    }
})
</script>