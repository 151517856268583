<template>
  <div v-if="vatPercentsUsed">
    <div v-if="vatPercentsUsed['ECRITURES'] && vatPercentsUsed['ECRITURES'].length > 0">
      {{ getLocalizedText(labels["vatPercentsUsedEcritures"]) }}
      <b-table 
        outlined striped
        :items="vatPercentsUsed['ECRITURES']"
        :fields="vatPercentsUsedFields"
        ref="vatPercentsUsedEcritures">
      </b-table>
    </div>
    <div v-if="vatPercentsUsed['COMMINGLINGS'] && vatPercentsUsed['COMMINGLINGS'].length > 0">
      {{ getLocalizedText(labels["vatPercentsUsedComminglings"]) }}
      <b-table 
        outlined striped
        :items="vatPercentsUsed['COMMINGLINGS']"
        :fields="vatPercentsUsedFields"
        ref="vatPercentsUsedComminglings">
      </b-table>
    </div>
    <div v-if="vatPercentsUsed['OTHERS'] && vatPercentsUsed['OTHERS'].length > 0">
      {{ getLocalizedText(labels["vatPercentsUsedOthers"]) }}
      <b-table 
        outlined striped
        :items="vatPercentsUsed['OTHERS']"
        :fields="vatPercentsUsedFields"
        ref="vatPercentsUsedOthers">
      </b-table>
    </div>
  </div>
</template>

<style scoped>
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

export default defineComponent({
    props: {
        vatPercentsUsed: {
          type: Object,
          required: false
        }
    },
    components: {
      
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const labels = {
        "vatPercentsUsedEcritures" : {
          "fr" : "Taux TVA utilisés dans les écritures",
          "en" : "VAT percents used in ecritures"
        },
        "vatPercentsUsedComminglings" : {
          "fr" : "Taux TVA utilisés dans les comminglings",
          "en" : "VAT percents used in comminglings"
        },
        "vatPercentsUsedOthers" : {
          "fr" : "Taux TVA utilisés ni dans ecritures ni dans comminglings",
          "en" : "VAT percents used in others"
        }
      }

      const vatPercentsUsedFields = [
        {
          key: "countryCode"
        },
        {
          key: "percentKey"
        },
        {
          key: "usedFrom",
          formatter: (value:string[], key:any, item:any) => {
            return value.join(", ")
          }
        }
      ]

      return { 
        vatPercentsUsed:props.vatPercentsUsed,
        vatPercentsUsedFields,
        labels
      }
    }
})
</script>