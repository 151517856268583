<template>
  <div class="ibox generate">
    <div class="ibox-title">
      <h2>{{ $t('amazon.generate.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <Messages :messages="errors" type="error"></Messages>
      <Messages :messages="successes" type="success"></Messages>

      <div class="row">
        <div class="col-lg-12">

          <form role="form" @submit="onFormSubmit" name="generateForm">
            <div class="form-group row">
                <label for="generateCSVFile" class="col-lg-3 col-form-label">{{ getLocalizedText(labels.generateCSVFile_label) }}</label>
                <div class="col-lg-9">
                  <b-form-file
                    id="generateCSVFile"
                    v-model="generateForm.file"
                    :state="Boolean(generateForm.file)"
                    :placeholder="getLocalizedText(labels.generateCSVFile_placeholder)"
                    :drop-placeholder="getLocalizedText(labels.generateCSVFile_drop_placeholder)"
                    required
                  />
                </div>
            </div>
            <SwitchCheck 
              v-if="isVatValidationAllowed"
              v-bind:value.sync="generateForm.validateVATNumbers" 
              id="validateVATNumbers" 
              :label="getLocalizedText(labels.validateVATNumbers)" 
              :labelAsColumn="true"
            />
            <SwitchCheck 
              v-if="hasUserRole(apiRoles.superadmin)"
              v-bind:value.sync="generateForm.isDebug" 
              id="isDebug" 
              :label="'Debug'" 
              :labelAsColumn="true"
            />
          </form>

        </div>
      </div>

      <div class="row check" v-if="check">
        <div class="col-lg-12">
          <div :class="'panel ' + (check.validated ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ check.validated ? getLocalizedText(labels.checkValid) : getLocalizedText(labels.checkInvalid) }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-lg-3">{{ getLocalizedText(labels.vatNumber) }}</div>
                <div class="col-lg-9">{{ check.vatNumber }}</div>
              </div>
              <div class="row" v-if="check.validated">
                <div class="col-lg-3">{{ getLocalizedText(labels.name) }}</div>
                <div class="col-lg-9">{{ check.name }}</div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.address) }}</div>
                  <div class="col-lg-9">{{ check.address }}</div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.periodLastDay) }}</div>
                  <div class="col-lg-9">{{ renderDate(check.periodLastDay) }}</div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.listCurrencyCodes) }}</div>
                  <div class="col-lg-9">{{ check.listCurrencyCodes.join(", ") }}</div>
                </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.listTaxableJuridictions) }}</div>
                  <div class="col-lg-9">{{ check.listTaxableJuridictions.join(", ") }}</div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.listDepartCountries) }}</div>
                  <div class="col-lg-9">
                    <div v-if="check.listDepartCountries['UE']">
                      UE: {{ check.listDepartCountries['UE'].join(", ") }}
                    </div>
                    <div v-if="check.listDepartCountries['HUE']">
                      Hors UE: {{ check.listDepartCountries['HUE'].join(", ") }}
                    </div>
                  </div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.listDestinationCountries) }}</div>
                  <div class="col-lg-9">
                    <div v-if="check.listDestinationCountries['UE']">
                      UE: {{ check.listDestinationCountries['UE'].join(", ") }}
                    </div>
                    <div v-if="check.listDestinationCountries['HUE']">
                      Hors UE: {{ check.listDestinationCountries['HUE'].join(", ") }}
                    </div>
                  </div>
              </div>
              <div class="row" v-if="check.validated">
                  <div class="col-lg-3">{{ getLocalizedText(labels.listMarketplaces) }}</div>
                  <div class="col-lg-9">
                      {{ check.listMarketplaces.join(", ") }}
                  </div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <button v-if="report == null" class="btn btn-primary ladda-button" data-style="zoom-in" type="button" @click="onFormSubmit">{{ getLocalizedText(labels.generateButton) }}</button>
          <div v-if="message != ''" class="message">{{message}}</div>
          <div v-if="percent != undefined" class="progress">
            <div :style="'width: '+percent+'%;'" class="progress-bar"></div>
          </div>
        </div>
      </div>

      <div class="row generate-success" v-if="report">
        <div class="col-lg-12">
          <div :class="'panel panel-primary'">
            <div class="panel-heading">
                {{ getLocalizedText(labels.reportTitle) }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col">
                  <Report :report="report" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-lg-12 p-xs">
                <a class="btn btn-primary" href="javascript:void(0)" @click="resetForm()">{{ getLocalizedText(labels.resetButton) }}</a>
                <a v-if="report.report && report.report.XLSReport" :href="getDownloadURL(report.report.XLSReport)" target="_blank" class="btn btn-secondary"><i class="fa fa-file-excel-o"></i> {{ getLocalizedText(labels.downloadButton) }}</a>
              </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .generate-success .fa {
    font-size: 1.3em;
  }
</style>



<script lang="ts">
import Messages from '@root/src/client/components/panels/Messages.vue';
import { Message } from '@fwk-client/store/types';
import * as Ladda from 'ladda';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, formatDate, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import Report from './report/Report.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';

import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { useReportAdmin } from '../../composables/useReportAdmin';
import { useGenerateAdmin } from '../../composables/useGenerateAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Messages,
      Select,
      Report,
      SwitchCheck
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedModule, modules } = useModuleAdmin(props, context);
      const { generateForm, checkFile, generateReportSocket } = useGenerateAdmin(props, context);
      const { getReportURL } = useReportAdmin(props, context);

      const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

      const check:Ref<any> = ref(null);
      const report:Ref<any> = ref(null);

      const listLoading:Ref<boolean> = ref(false);

      const errors:Ref<Message[]> = ref([]);
      const successes:Ref<Message[]> = ref([]);
      const message:Ref<string> = ref("");
      const percent:Ref<number|undefined> = ref(undefined);

      const isVatValidationAllowed = computed(() => {
        if(selectedModule && selectedModule.value && selectedModule.value.options && selectedModule.value.options.isAmazonVatValidationAllowed) {
          return selectedModule.value.options.isAmazonVatValidationAllowed
        }
        return false;
      })

      const labels = {
        "generateCSVFile_label" : {
          "fr" : "Fichier",
          "en" : "File"
        },
        "generateCSVFile_placeholder" : {
          "fr" : "Sélectionnez le fichier",
          "en" : "Select the file"
        },
        "generateCSVFile_drop_placeholder" : {
          "fr" : "Sélectionnez le fichier ou déposez le ici",
          "en" : "Select the file or drop it here"
        },
        "validateVATNumbers" : {
          "fr" : "Valider les numéros TVA",
          "en" : "Validate VAT numbers"
        },
        "checkValid" : {
          "fr" : "Fichier valide",
          "en" : "Valid file"
        },
        "checkInvalid" : {
          "fr" : "Fichier invalide",
          "en" : "Invalid file"
        },
        "vatNumber" : {
          "fr" : "Numéro de TVA",
          "en" : "VAT number"
        },
        "name" : {
          "fr" : "Entreprise",
          "en" : "Company"
        },
        "address" : {
          "fr" : "Adresse",
          "en" : "Address"
        },
        "periodLastDay" : {
          "fr" : "Date de fin de période",
          "en" : "Period last day"
        },
        "generateButton" : {
          "fr" : "Générer",
          "en" : "Generate"
        },
        "listCurrencyCodes" : {
          "fr" : "Codes devises",
          "en" : "Currency codes"
        },
        "listDepartCountries" : {
          "fr" : "Pays de départ",
          "en" : "Depart countries"
        },
        "listDestinationCountries" : {
          "fr" : "Pays de destination",
          "en" : "Destination countries"
        },
        "listTaxableJuridictions" : {
          "fr" : "Juridictions taxables",
          "en" : "Taxable juridictions"
        },
        "listMarketplaces" : {
          "fr" : "Marketplaces",
          "en" : "Marketplaces"
        },
        "reportTitle" : {
          "fr" : "Rapport",
          "en" : "Report"
        },
        "downloadButton" : {
          "fr" : "Télécharger",
          "en" : "Download"
        },
        "resetButton" : {
          "fr" : "Réinitialiser",
          "en" : "Reset"
        }
      }

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {

      })

      const enableLaddaButton = () => {
        var button:HTMLButtonElement|null = document.querySelector( '.check button' );
        laddaSubmit = Ladda.create(button!);
      }

      const onApiErrors = (apiErrors:Message[]) => {
        errors.value = apiErrors;
      }

      const onApiSuccesses = (apiSuccesses:Message[]) => {
        successes.value = apiSuccesses
      }

      const onApiUpdate = (data:any) => {
        if(data && data.message) {
          message.value = data.message;
        }
        if(data && data.percentage != undefined) {
          percent.value = data.percentage;
        }
        else {
          percent.value = undefined;
        }
      }

      const onCompleteHandler = (result:any) => {
        console.log("GENERATE - ON COMPLETE HANDLER")
        laddaSubmit!.stop();
        if(result.generated) {
          report.value = result.report;
          message.value = "";
          percent.value = undefined;
        }
      }

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();
      
        // We clear the current messages
        errors.value = [];
        successes.value = [];

        laddaSubmit!.start();

        generateReportSocket({
          successesHandler: onApiSuccesses, 
          errorsHandler: onApiErrors, 
          updatesHandler:onApiUpdate,
          completedHandlers:[onCompleteHandler]}).then((result:any) => {
            // We do nothing as acknoledgment of socket request.
        })
      }

      const getDownloadURL = (file:any) => {
        if(!file) return null;
        return getReportURL(file._id);
      }

      const resetForm = () => {
        generateForm.file = null;
        check.value = null;
        report.value = null;
      }

      const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

      const renderDate = (date:Date) => {
        // return formatDate(date, currentLanguageCode, {type: FormatDateType.SHORT, isUTC:true});
        return formatDay(date, currentLanguageCode, {type: FormatDateType.SHORT, isUTC:true});
      }

      watch(
        () => generateForm.file,
        (val:any, oldVal:any) => {
          if(val != undefined && val.name != "") {
            check.value = null;
            report.value = null;
            listLoading.value = true;

            // We clear the current messages
            errors.value = [];
            successes.value = [];

            checkFile({errorsHandler: onApiErrors}).then((result) => {
              listLoading.value = false;
              if(result.validated) {
                check.value = result,
                nextTick(() => {
                  enableLaddaButton();
                })
              }
              else {
                resetForm();
              }
            })
          }
          else {
            // We reset the check file output
          }
        },
        { deep: true }
      )

      return { 
        listLoading,
        modules,
        selectedModule,
        onFormSubmit,
        getDownloadURL,
        resetForm,
        generateForm,
        report,
        errors,
        successes,
        check,
        renderDate,
        labels,
        hasUserRole,
        apiRoles,
        message,
        percent,
        isVatValidationAllowed
      }
    }
})
</script>