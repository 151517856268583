<template>
  <b-table 
    v-if="conversionRates"
    outlined striped
    :items="conversionRates"
    :fields="conversionRatesFields"
    ref="conversionRates">
  </b-table>
</template>

<style scoped>
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

export default defineComponent({
    props: {
        conversionRates: {
          type: Array,
          required: false
        }
    },
    components: {

    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const conversionRatesFields = [
        {
          key: "currencyCode",
          label: "Code devise"
        },
        {
          key: "conversionRate",
          label: "Taux de conversion"
        }
      ]

      onMounted(() => {

      })

      return { 
        conversionRates:props.conversionRates,
        conversionRatesFields,
      }
    }
})
</script>