<template>
  
    <div v-if="rowUsed && rowUsed.length > 0">
      {{ getLocalizedText(labels[type]) }}
      <b-table 
        responsive
        outlined striped
        :items="rowUsed"
        :fields="rowsUsedSeveralTimesFields"
        ref="rowUSed">
      </b-table>
    </div>
                  
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { csvFileColumns } from '@igotweb-node-api/api/modules/amazon/src/models/csvFile';


export default defineComponent({
    props: {
        type: {
          type: String as PropType<"rowsUsedSeveralTimes" | "rowsUsedInEcrituresButNotMarketplace" | "rowsUsedInMarketplaceButNotEcritures">,
          required: true
        },
        rowUsed: {
          type: Array,
          required: false
        }
    },
    components: {

    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { type, rowUsed } = toRefs(props);

      const labels = {
        "rowsUsedSeveralTimes" : {
          "fr" : "Lignes utilisées plusieurs fois",
          "en" : "Rows used several times"
        },
        "rowsUsedInEcrituresButNotMarketplace" : {
          "fr" : "Lignes utilisées dans les écritures mais pas dans le marketplace",
          "en" : "Rows used in ecritures but not in marketplace"
        },
        "rowsUsedInMarketplaceButNotEcritures" : {
          "fr" : "Lignes utilisées dans le marketplace mais pas dans les écritures (Ajoutées dans VENTES FRANCE 20%)",
          "en" : "Rows used in marketplace but not in ecritures (Added in VENTES FRANCE 20%)"
        }
      }

      var detailsFieds = [];
      for(var columnKey of Object.keys(csvFileColumns)) {
        detailsFieds.push({
          key: "details." + columnKey,
          label: csvFileColumns[columnKey]
        })
      }

      const rowsUsedSeveralTimesFields = [
        {
          key: "indexRow"
        },
        {
          key: "impactedAmount",
          formatter: (value:number, key:any, item:any) => {
            return app.formatPriceAmount({amount:value})
          }
        },
        {
          key: "eligibleTypes",
          formatter: (value:string[], key:any, item:any) => {
            return value.join(", ")
          }
        },
        ...detailsFieds
      ]

      return { 
        type,
        rowUsed,
        labels,
        rowsUsedSeveralTimesFields
      }
    }
})
</script>